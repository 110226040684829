<template>
  <div class="main">
    <div class="div-search">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <div class="molded">
            <div class="div-select">
              <SelectStatic fieldTarget="filter" classCss="select-search" :onlyQuery="true" :initialValue="{
                content: 'Número da Locação',
                id: 'rentNumber',
              }" v-model="filter" :showBorder="false" :data="[
                { content: 'Número da Locação', id: 'rentNumber' },
                { content: 'Nome do Cliente', id: 'customerName' },
                { content: 'CNPJ/CPF', id: 'customerDocument' },
                { content: 'Nome do Produto', id: 'productName' },
                { content: 'Número do Patrimônio', id: 'patrimonyBarCode' },
              ]" />
            </div>
          </div>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <div class="molded">
            <input type="text" class="input-search" name="search" placeholder="Pesquisar ..."
              @keyup.enter.prevent="execute()" v-model="search" />
          </div>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <div class="div-btn">
            <Button _key="btnSearchPdv" color="white" type="info" title="Buscar"
              classIcon="fa-solid fa-magnifying-glass" size="medium" :clicked="execute" />
            <Button _key="btnClean" color="white" type="info" title="Limpar" classIcon="fa-solid fa-broom" size="medium"
              :clicked="clean" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Search from "@nixweb/nixloc-ui/src/component/shared/Search.vue";
import SelectStatic from "@nixweb/nixloc-ui/src/component/forms/SelectStatic.vue";

import { mapMutations } from "vuex";

export default {
  name: "SearchByFilter",
  props: ["value"],
  components: {
    Button,
    Search,
    SelectStatic,
  },
  data() {
    return {
      filter: { id: "rentNumber" },
      search: "",
    };
  },
  mounted() {
    this.removeLoading(["panel"]);
  },
  methods: {
    ...mapMutations("generic", ["removeLoading", "btnPdvSearch"]),
    execute() {
      this.$emit("input", { filter: this.filter.id, search: this.search });
    },
    clean() {
      this.$emit("input", { filter: this.filter.id, search: "" });
      this.search = "";
      this.removeLoading(["btnClean"]);
    },
  },
};
</script>

<style scoped>
.molded {
  max-width: 900px;
  height: 52px;
  font-size: 14px;
  padding: 5px 8px 6px 15px;
  margin-bottom: 20px;
  background-color: white;
  border: 1px solid #e5e4e8;
  border-radius: 40px;
  box-sizing: border-box;
  box-shadow: none;
  color: rgb(38, 41, 44);
  opacity: 1;
  transition: background-color 0.1s ease-in 0s, border 0.1s ease-in 0s;
}

input[type="text"] {
  width: 90%;
  height: 40px;
  font-size: 17px;
  border: 0px solid white;
  background-color: rgb(255, 255, 255);
  margin-bottom: 50px;
  margin-left: 20px;
}

input[type="text"]:focus {
  outline: none;
}

.div-select {
  margin-top: -25px;
  margin-right: 5px;
  width: 100%;
}

.div-search {
  margin: 0 auto;
}

.div-btn {
  margin-top: 5px;
}
</style>