<template>
  <div>
    <SearchByFilter v-model="search" />
    <Loading type="line" :center="false" v-show="loading" />
    <Alert type="warning" v-if="list.length == 0 && showAlert">
      <span> Nenhuma <b>Locação</b> com <b>Retorno </b>pendente encontrada! </span>
    </Alert>
    <div v-if="list.length > 0">
      <div v-if="list[0].productFullName">
        <i class="fa-solid fa-magnifying-glass"></i>
        <b> {{ list[0].productFullName }}</b> com patrimonio nº <b>{{ list[0].patrimonyBarCode }}</b>
      </div>
    </div>
    <div v-for="rent in list" :key="rent.id">
      <div class="div-rent">
        <div class="div-item">
          <Molded :borderRadius="12">
            <div>
              <b-row>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                  <div class="side-by-side number">
                    <span class="title"> {{ rent.numberAndDate }}</span>
                  </div>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                  <div class="identification max-identification text-center">
                    <span v-if="rent.identification">
                      {{ rent.identification }}</span>
                    <span v-else>Sem identificação</span>
                  </div>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                  <div class="title max-title">
                    <span class="icon-customer"><i class="fa-duotone fa-user-tie"></i></span>
                    {{ rent.customerFullName }}
                  </div>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                  <div class="text-right">
                    <div class="side-by-side">
                      <ButtonMovimentStock :showTitleButton="false" :keyButton="rent.id" :rentId="rent.id"
                        :periodRentId="null" />
                    </div>
                    <div class="side-by-side">
                      <Button :_key="rent.id + 'eye'" tooltip="Visualizar" type="primary" classIcon="fa-solid fa-eye"
                        size="small" :clicked="executeOpenModal" :params="rent" />
                    </div>
                    <div class="side-by-side">
                      <Button :_key="rent.id + 'share'" tooltip="Compartilhar" type="info"
                        classIcon="fa-sharp fa-regular fa-share-nodes" size="small" :clicked="printRent"
                        :params="rent" />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </Molded>
        </div>
      </div>
    </div>
    <Modal :title="'Locação'" :width="900" :height="750" v-if="showModal('operationRent')">
      <OperationRent module="Operational" :rentId="rentId" />
    </Modal>
    <PrintPreview v-if="print" module="Operational" :rentId="rentId" :periodRentId="periodRentId"
      :onHideModal="closeModal" />
  </div>
</template>
<script>


import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import SearchByFilter from "./SearchByFilter.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import ButtonMovimentStock from './ButtonMovimentStock.vue'
import PrintPreview from '../adm/document/PrintPreview.vue'
import OperationRent from './OperationRent.vue'

import { mapActions, mapMutations, mapGetters, mapState } from "vuex";

export default {
  name: "SearchList",
  props: ["filter"],
  components: { SearchByFilter, Loading, Alert, Molded, ButtonMovimentStock, Button, Modal, OperationRent, PrintPreview },
  data() {
    return {
      search: {},
      list: [],
      urlGetAll: "/api/v1/operational/search",
      loading: false,
      showAlert: false,
      rentId: "",
      print: false,
    };
  },
  mounted() {
    this.list = this.searchRent;
  },
  computed: {
    ...mapState("pdv", ["searchRent"]),
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    ...mapMutations("generic", ["openModal", "removeLoading"]),
    ...mapMutations("pdv", ["updateSearchRent"]),
    getAll(filter, search) {
      this.loading = true;
      let params = {
        url: this.urlGetAll,
        obj: {
          filter: filter,
          search: search,
        },
      };
      this.getApi(params).then((response) => {
        this.loading = false;
        this.list = response.content;
        this.updateSearchRent(this.list);
        this.showAlert = true;
        this.removeLoading(["btnSearchPdv"]);
      });
    },
    executeOpenModal(rent) {
      this.rentId = rent.id;
      this.openModal("operationRent");
      this.removeLoading([rent.id + 'eye']);
    },
    printRent(rent) {
      this.print = true;
      this.rentId = rent.rentId;
      this.removeLoading([rent.id + 'share']);
    },
  },
  watch: {
    search: {
      handler(search) {
        this.getAll(search.filter, search.search);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-rent {
  margin-top: 10px;
  margin: auto;
}

.div-item {
  margin-top: 10px;
}

.number {
  font-size: 14px;
  margin-left: 20px;
}

.title {
  font-size: 15px;
}

.identification {
  font-size: 13px;
  background-color: #F1F3F4;
  border-radius: 20px;
  padding: 3px;
}

.icon-customer {
  margin-right: 10px;
}


.max-title {
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-identification {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}



.icon-default {
  font-size: 20px;
  color: #dde3ea;
}
</style>